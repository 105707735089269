<template>
  <label
    class="checkbox__container"
    :class="{ 'checkbox__container--disabled': disabled }"
  >
    <span
      v-if="isLabelLeft && label"
      class="checkbox__label checkbox__label--left"
    >
      {{ label }}
    </span>
    <input
      :value="value"
      :checked="shouldBeChecked"
      :disabled="disabled"
      type="checkbox"
      class="checkbox"
      @change="onChange"
    />
    <span class="checkbox__fake"></span>
    <span
      v-if="isLabelRight && label"
      class="checkbox__label checkbox__label--right"
    >
      {{ label }}
    </span>
  </label>
</template>

<script>
export default {
  name: 'SmCheckbox',

  model: {
    prop: 'modelValue',
    event: 'change',
  },

  props: {
    label: {
      type: String,
    },

    labelPosition: {
      type: String,
      default: 'right',
    },

    value: {
      type: [Boolean, Array, Object],
    },

    modelValue: {
      default: false,
    },

    trueValue: {
      default: true,
    },

    falseValue: {
      default: false,
    },

    disabled: {
      default: false,
    },
  },

  computed: {
    shouldBeChecked() {
      if (this.modelValue instanceof Array) {
        // Для сравнения двух объектов, не по ссылке
        for (const item of this.modelValue) {
          let isEqual = this.lodash.isEqual(item, this.value);
          console.log(isEqual);
          if (isEqual) {
            return true;
          }
        }
        return this.modelValue.includes(this.value);
      }
      return this.modelValue === this.trueValue;
    },

    isLabelLeft() {
      return this.labelPosition === 'left';
    },

    isLabelRight() {
      return !this.isLabelLeft;
    },
  },

  methods: {
    onChange(event) {
      let isChecked = event.target.checked;

      if (this.modelValue instanceof Array) {
        let newValue = [...this.modelValue];

        isChecked
          ? newValue.push(this.value)
          : newValue.splice(newValue.indexOf(this.value), 1);

        this.$emit('change', newValue);
      } else {
        this.$emit('change', isChecked ? this.trueValue : this.falseValue);
      }
    },
  },
};
</script>

<style lang="scss">
.checkbox {
  display: none;
}

.checkbox__container {
  display: inline-flex;
  align-items: center;

  cursor: pointer;
}

// .checkbox__container--disabled {
//   cursor: not-allowed;
// }

.checkbox__fake {
  content: '';

  position: relative;

  display: inline-block;
  width: 20px;
  height: 20px;

  border: 1px solid var(--gray);
  border-radius: 50%;

  background-color: var(--white);
  transition: border-color 0.3s, background 0.3s;
}

.checkbox__fake::before {
  content: '';

  position: absolute;
  top: -3px;
  left: 6px;
  z-index: 1;

  display: block;
  width: 7px;
  height: 15px;

  border-right: 2px solid var(--blue);
  border-bottom: 2px solid var(--blue);

  opacity: 0;

  transform: rotate(39deg);
  transition: opacity 0.2s;
}

.checkbox:checked + .checkbox__fake:before {
  opacity: 1;
}

.checkbox:checked + .checkbox__fake {
  border-color: var(--blue);
}

.checkbox:disabled + .checkbox__fake {
  border-color: var(--gray);
  background-color: rgba(var(--rgb-gray), 0.2);
}

.checkbox:checked:disabled + .checkbox__fake:before {
  border-color: var(--gray);
}

.checkbox__label {
  margin: 0;

  font-size: 16px;
  line-height: 1;
  color: var(--black);
}

.checkbox__label--left {
  padding-right: 5px;
}

.checkbox__label--right {
  padding-left: 5px;
}
</style>
